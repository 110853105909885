import axios from 'axios';
import MockAdapter from "axios-mock-adapter";

export const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
})
export const httpSmartdatanet = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL_SMARTDATANET
})

if (process.env.VUE_APP_API_MOCKS_ENABLED == "true") {
    enableMocks(http);
}
addHeaders(http);
addHeaders(httpSmartdatanet);

function addHeaders(http) {
    http.interceptors.request.use(cfg => {
        cfg.headers = cfg.headers || {};
        return cfg;
    });
}

// MOCKS
// ---------------------------------------------------------------------------------------------------------------------
function getStories() {
    let data = {
        button: {
            name: "Tutte le storie",
            route: "/storie",
        },
        cards: [
            {
                descripition: "La qualità dell’aria in Piemonte",
            },
            {
                descripition: "Flussi turistici verso il Piemonte dal 2009 al 2017",
            },
            {
                descripition:
                    "Patrimonio bibliotecario della Regione Piemonte",
            },
        ],
    };
    return [200, data];
}

function getDataViz() {
    let data = {
        button: {
            name: "Vedi tutte",
            route: "/dataviz",
        },
        cards: [
            {
                descripition: "Sed imperdiet volutpat erat Sed imperdiet volutpat erat Sed imperdiet volutpat erat",
            },
            {
                descripition: "Sed imperdiet volutpat erat Sed imperdiet volutpat erat",
            },
            {
                descripition: "Sed imperdiet volutpat erat",
            },
        ],
    };
    return [200, data];
}

function getNews() {
    let data = {
        button: {
            name: "Tutte le news",
            route: "/news",
        },
        cards: [
            {
                date: "06 maggio 2020",
                descripition: "Dati.piemonte si aggiorna, pubblicati i dati d’interesse trasversale",
            },
            {
                date: "06 maggio 2020",
                descripition: "Il Catalogo Nazionale dati.gov.it in evoluzione",
            },
            {
                date: "06 maggio 2020",
                descripition:
                    "Partecipa a un sondaggio - il progetto europeo DEAS",
            },
        ],
    };
    return [200, data];
}

function getDatasetCount() {
    let data = {
        count: 1014
    };
    return [200, data];
}

function getTagList() {
    let data = [
        { label: "ambiente", count: "59" },
        { label: "Biota", count: "47" },
        { label: "climatologia - meteorologia - atmosfera", count: "37" },
        { label: "imprese", count: "43" },
        { label: "mappe di base - immagini - copertura terrestre", count: "47" },
        { label: "italia", count: "61" },
        { label: "piemonte", count: "74" },
        { label: "informazioni geoscientifiche", count: "82" },
        { label: "natural risk zones", count: "54" },
        { label: "pianificazione - catasto", count: "168" },
        { label: "regionali", count: "241" },
        { label: "rndt", count: "257" },
        { label: "salute", count: "35" },
        { label: "siti protetti", count: "61" },
        { label: "trasporti", count: "35" },
        { label: "turismo", count: "43" },
        { label: "utilizzo del territorio", count: "138" },
    ];
    return [200, data];
}

function getThemeList() {
    let data = [
        { label: "Trasporti", img: "trasporti", count: "224" },
        { label: "Giustizia, sistema giuridico e sicurezza pubblica", img: "giustizia", count: "123" },
        { label: "Tematiche internazionali", img: "mondo", count: "121" },
        { label: "Salute", img: "salute", count: "112" },
        { label: "Governo e settore pubblico", img: "governo", count: "45" },
        { label: "Popolazione società", img: "popolazione", count: "69" },
        { label: "Regioni e città", img: "mappa", count: "75" },
        { label: "Ambiente", img: "ambiente", count: "88" },
        { label: "Energia", img: "batteria", count: "55" },
        { label: "Istruzione, cultura e sport", img: "istruzione", count: "45" },
        { label: "Agricoltura, pesca, silvicoltura e prodotti alimentari", img: "agricoltura", count: "46" },
        { label: "Economia e finanze", img: "lavagna", count: "79" },
        { label: "Scienza e tecnologia", img: "scienza", count: "89" }
    ];
    return [200, data];
}

function getDatasetList() {
    let data = [
        {
            id: 1,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Trasporti"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: true,
            hasSinglePage: false
        },
        {
            id: 2,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Popolazione e società"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: false,
            hasSinglePage: true
        },
        {
            id: 3,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Trasporti"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: true,
            hasSinglePage: false
        },
        {
            id: 4,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Popolazione e società"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: false,
            hasSinglePage: true
        },
        {
            id: 5,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Popolazione e società"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: false,
            hasSinglePage: true
        },
        {
            id: 6,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Popolazione e società"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: false,
            hasSinglePage: true
        },
        {
            id: 7,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Popolazione e società"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: false,
            hasSinglePage: true
        },
        {
            id: 8,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Popolazione e società"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: false,
            hasSinglePage: true
        },
        {
            id: 9,
            title: "Carta della sensibilita’ visiva - sito unesco “i paesaggi vitivinicoli del piemonte”",
            themes: ["Popolazione e società"],
            description: "Punti di osservazione (Belvedere) selezionati dai Comuni del sito UNESCO “I paesaggi vitivinicoli del Piemonte: Langhe-Roero e Monferrato”, funzionali alla redazione.",
            ente: "Regione Piemonte",
            pubblicato: "Yucca Smart Data Net",
            aggiornato: "12/10/2019",
            tags: ["pianificazione - catasto", "geoportale", "utilizzo del territorio"],
            downloadCsv: false,
            hasSinglePage: true
        },
    ];
    return [200, data];
}

// REGISTER MOCKS
function enableMocks(http) {
    const mock = new MockAdapter(http, { delayResponse: 700 });

    mock.onGet("/stories").reply(getStories);
    mock.onGet("/dataviz").reply(getDataViz);
    mock.onGet("/news").reply(getNews);
    mock.onGet("/datasetcount").reply(getDatasetCount);
    mock.onGet("/tags").reply(getTagList);
    mock.onGet("/themes").reply(getThemeList);
    mock.onGet("/datasetlist").reply(getDatasetList);
}