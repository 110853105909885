<template>
  <div id="open-data">
    <v-app>
      <router-view></router-view>
      <notifications
        group="userFeedback"
        position="bottom left"
        :speed="600"
      ></notifications>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  updated: function () {
    this.$nextTick(function () {
      Array.prototype.forEach.call(document.getElementsByClassName('ccb__edit'), (edit) => {
        edit.addEventListener('click', (event) => {
          event.preventDefault();
          window.CookieConsent.lastActiveElement = document.activeElement;
          document.getElementById('cconsent-modal').className = 'ccm--visible';
          document.querySelector('.ccm__cheading__close').focus();
        });
      });
    })
  }
};
</script>

<style lang="scss">
@import "./styles/variables.scss";

#open-data {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-dark;
  font-size: 12px;
}
.v-application {
  font-family: "Open Sans", sans-serif !important;
}
</style>
