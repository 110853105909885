import Vue from "vue";

Vue.filter('numbDate', function (v, defaultValue = "-") {
    let d = new Date(v)

    const ye = new Intl.DateTimeFormat('it', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('it', { month: 'numeric' }).format(d);
    const da = new Intl.DateTimeFormat('it', { day: 'numeric' }).format(d);
    let res = da + "/" + mo + "/" + ye

    return v ? res : defaultValue;
});

Vue.filter('newsDate', function (v, defaultValue = "-") {
    // format from it 26-01-2017 tp 2017-01-26
    let n = v.split("-")
    let d = new Date(n[2], n[1] - 1, n[0])

    const ye = new Intl.DateTimeFormat('it', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('it', { month: 'long' }).format(d);
    const da = new Intl.DateTimeFormat('it', { day: '2-digit' }).format(d);
    let res = da + " " + mo + " " + ye

    return v ? res : defaultValue;
});

Vue.filter('tableDate', function (v, defaultValue = "-") {
    // remove +0120 timezone to format
    let splitValue = v.split("+");
    let dateOffset = parseInt(splitValue[1])
    let dateMillisec = parseInt(splitValue[0])
    let d = new Date(dateMillisec);

    var utc = dateMillisec - (dateOffset * 60000);
    var nd = new Date(utc + (d.getTimezoneOffset() * 60000));

    const ye = new Intl.DateTimeFormat('it', { year: 'numeric' }).format(nd);
    const mo = new Intl.DateTimeFormat('it', { month: 'numeric' }).format(nd);
    const da = new Intl.DateTimeFormat('it', { day: 'numeric' }).format(nd);
    let res = da + "/" + mo + "/" + ye

    return v ? res : defaultValue;
});

export const SOURCE_LIST = {
    GEOPORTALE: "Geoportale Regione Piemonte",
    GEOPORTALE_REGIONE_PIEMONTE: "Geoportale Regione Piemonte",
    WEBGIS: "Geoportale Arpa Piemonte",
    GEOPORTALE_ARPA_PIEMONTE: "Geoportale Arpa Piemonte",
    SDP: "Yucca SDP",
    YUCCA_SDP: "Yucca SDP",
};

export const ORDERS = ["più recenti", "meno recenti", "rilevanza"];

export const ORDERS_PARAMS = {
    "più recenti": [
        {
            field: "metadataModified",
            sortingStrategy: "desc",
        },
    ],
    "meno recenti": [
        {
            field: "metadataModified",
            sortingStrategy: "asc",
        },
    ],
    "rilevanza": [],
};

export const DATASET_FILTERS = {
    tags: "datasetTagList",
    topics: "datasetTopicsList",
    owners: "datasetOwnersList"
};

export const animateValue = (id, start, end, duration, increment) => {
    if (start === end) return;
    var range = end - start;
    var current = start;
    var stepTime = Math.abs(Math.floor(duration / range));
    var obj = document.getElementById(id);
    var timer = setInterval(function () {
        if (current + increment > end) {
            current = end;
        }
        current += increment;
        obj.innerHTML = current;
        if (current == end) {
            clearInterval(timer);
        }
    }, stepTime);
};

export default { name: "globals" }