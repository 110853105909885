import Vue from "vue";
import Vuex from "vuex";
import { loadDatasetList } from "../services/api";
import { DATASET_FILTERS } from "../boot/globals";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    count: 0,
    isHomePage: false,
    searchParams: {
      "query": "",
      "pageIndex": 0,
      "pageSize": 10,
      "sorting": [
        {
          field: "metadataModified",
          sortingStrategy: "desc",
        },
      ],
      "owners": [],
      "tags": [],
      "topics": [],
      "advanced": true
    },
    storieList: [],
    entiList: [],
    dataVizList: [],
    newsList: [],
    datasetTagList: [],
    datasetTopicsList: [],
    datasetOwnersList: [],
    homeTagList: [],
    homeTopicsList: [],
    homeOwnersList: [],
    datasetList: {},
    datasetDetail: {},
    comeFarePer: []
  },
  getters: {
    isHomePage: state => state.isHomePage,
    getSearchParams: state => state.searchParams,
    getStorieList: state => state.storieList,
    getEntiList: state => state.entiList,
    getDataVizList: state => state.dataVizList,
    getNewsList: state => state.newsList,
    getDatasetTagsList: state => state.datasetTagList,
    getDatasetTopicsList: state => state.datasetTopicsList,
    getDatasetOwnersList: state => state.datasetOwnersList,
    getHomeTagsList: state => state.homeTagList,
    getHomeTopicsList: state => state.homeTopicsList,
    getHomeOwnersList: state => state.homeOwnersList,
    getDatasetList: state => state.datasetList,
    getDatasetDetail: state => state.datasetDetail,
    getComeFarePer: state => state.comeFarePer
  },
  mutations: {
    CREATE_HOME(state) {
      state.isHomePage = true
    },
    DESTROY_HOME(state) {
      state.isHomePage = false
    },
    CLEAR_SEARCH_PARAMS(state) {
      state.searchParams = {
        "query": "",
        "pageIndex": 0,
        "pageSize": 10,
        "sorting": [
          {
            field: "metadataModified",
            sortingStrategy: "desc",
          },
        ],
        "owners": [],
        "tags": [],
        "topics": [],
        "advanced": true
      };
      state.datasetTagList = [];
      state.datasetTopicsList = [];
      state.datasetOwnersList = [];
      state.datasetList = {};
    },
    SET_SEARCH_PARAMS(state, { searchParams }) {
      state.searchParams = searchParams
      state.searchParams.pageIndex = 0
    },
    SET_PAGE_INDEX(state, { pageIndex }) {
      state.searchParams.pageIndex = pageIndex
    },
    SET_DATASET_DETAIL(state, { datasetDetail }) {
      state.datasetDetail = datasetDetail
    },
    SET_STORIE_LIST(state, { storieList }) {
      state.storieList = storieList
    },
    SET_ENTI_LIST(state, { entiList }) {
      state.entiList = entiList
    },
    SET_DATAVIZ_LIST(state, { dataVizList }) {
      state.dataVizList = dataVizList
    },
    SET_NEWS_LIST(state, { newsList }) {
      state.newsList = newsList
    },
    SET_HOME_TAG_LIST(state, { tagList }) {
      //build display values
      var displayFilters = [];
      tagList.forEach(item => {
        if (item.name != undefined) {
          let displayValue = item.name.toLowerCase() == "opendata" ? "geoportale" : item.name;

          let fullFilter = {
            frequency: item.frequency,
            name: item.name,
            displayValue: displayValue
          };

          displayFilters.push(fullFilter);
        }
      });
      //set tags
      state.homeTagList = displayFilters
    },
    SET_HOME_TOPICS_LIST(state, { topicsList }) {
      state.homeTopicsList = topicsList
    },
    SET_HOME_OWNERS_LIST(state, { ownersList }) {
      state.homeOwnersList = ownersList
    },
    SET_COME_FARE_PER(state, { comeFarePer }) {
      state.comeFarePer = comeFarePer
    },
    async LOAD_DATASET_LIST(state) {
      if (state.searchParams.query == "" && state.searchParams.owners.length == 0 && state.searchParams.tags.length == 0 && state.searchParams.topics.length == 0) {
        this.dispatch("clearSearchParams");
      } else {
        let datasetListPromise = loadDatasetList(state.searchParams);

        try {
          let { data } = await datasetListPromise;
          state.datasetList = data;

          let filters = data.advancedDetails?.availableFilters ?? null;
          if (filters) {
            filters.forEach(filter => {
              //build display values
              var displayFilters = [];
              filter.data.forEach(filterItem => {
                if (filterItem.term != undefined) {
                  let displayValue = filterItem.term.toLowerCase() == "opendata" ? "geoportale" : filterItem.term.length < 30 ? filterItem.term : filterItem.term.substring(0, 25) + '...';

                  let fullFilter = {
                    frequency: filterItem.frequency,
                    term: filterItem.term,
                    displayValue: displayValue
                  };

                  displayFilters.push(fullFilter);
                }
              });
              //set filter
              let filterParam = DATASET_FILTERS[filter.name]
              state[filterParam] = displayFilters;
            });
          }
        } catch (err) {
          console.log(err);
        }

      }
    }
  },
  actions: {
    createHome: ({ commit }) => commit("CREATE_HOME"),
    destroyHome: ({ commit }) => commit("DESTROY_HOME"),
    clearSearchParams: ({ commit }) => commit("CLEAR_SEARCH_PARAMS"),
    setSearchParams: ({ commit, dispatch }, { searchParams }) => {
      commit("SET_SEARCH_PARAMS", { searchParams });
      dispatch('loadDatasetList');
    },
    setPageIndex: ({ commit, dispatch }, { pageIndex }) => {
      commit("SET_PAGE_INDEX", { pageIndex });
      dispatch('loadDatasetList');
    },
    setDatasetDetail: ({ commit }, { datasetDetail }) => commit("SET_DATASET_DETAIL", { datasetDetail }),
    setEntiList: ({ commit }, { entiList }) => commit("SET_ENTI_LIST", { entiList }),
    setStorieList: ({ commit }, { storieList }) => commit("SET_STORIE_LIST", { storieList }),
    setDataVizList: ({ commit }, { dataVizList }) => commit("SET_DATAVIZ_LIST", { dataVizList }),
    setNewsList: ({ commit }, { newsList }) => commit("SET_NEWS_LIST", { newsList }),
    setHomeTagList: ({ commit }, { tagList }) => commit("SET_HOME_TAG_LIST", { tagList }),
    setHomeTopicsList: ({ commit }, { topicsList }) => commit("SET_HOME_TOPICS_LIST", { topicsList }),
    setHomeOwnersList: ({ commit }, { ownersList }) => commit("SET_HOME_OWNERS_LIST", { ownersList }),
    setComeFarePer: ({ commit }, { comeFarePer }) => commit("SET_COME_FARE_PER", { comeFarePer }),
    loadDatasetList: ({ commit }) => commit("LOAD_DATASET_LIST"),
  },
});