
export const HOME = {
    path: "home",
    name: "Home",
    meta: {
        title: "home",
    },
    component: () => import("@/views/Home.vue")
};
export const CATALOGO = {
    path: "catalogo",
    name: "Catalogo",
    meta: {
        title: "Catalogo",
    },
    component: () => import("@/views/Catalogo.vue")
};
export const CATALOGO_DETAIL = {
    path: "catalogodetail/:id",
    name: "CatalogoDetail",
    meta: {
        title: "Catalogo",
    },
    component: () => import("@/views/CatalogoDetail.vue")
};
export const ENTI = {
    path: "enti",
    name: "Enti",
    meta: {
        title: "Enti",
    },
    component: () => import("@/views/Enti.vue")
};
export const ENTI_DETAIL = {
    path: "enti/:id",
    name: "EntiDetail",
    meta: {
        title: "Enti",
    },
    component: () => import("@/views/EntiDetail.vue")
};
export const DATAVIZ = {
    path: "dataviz",
    name: "DataViz",
    meta: {
        title: "Dataviz",
    },
    component: () => import("@/views/Dataviz.vue")
};
export const DATAVIZ_DETAIL = {
    path: "dataviz/:id",
    name: "DataVizDetail",
    meta: {
        title: "Data viz",
    },
    component: () => import("@/views/DataVizDetail.vue")
};
export const STORIE = {
    path: "storie",
    name: "Storie",
    meta: {
        title: "Storie",
    },
    component: () => import("@/views/Storie.vue")
};
export const STORIE_DETAIL = {
    path: "storie/:id",
    name: "StorieDetail",
    meta: {
        title: "Storie",
    },
    component: () => import("@/views/StorieDetail.vue")
};
export const RICERCARE = {
    path: "ricercare",
    name: "Ricercare",
    meta: {
        title: "Come fare per",
    },
    component: () => import("@/views/ComeFarePer.vue")
};
export const RIUSARE = {
    path: "riusare",
    name: "Riusare",
    meta: {
        title: "Come fare per",
    },
    component: () => import("@/views/ComeFarePer.vue")
};
export const ADERIRE = {
    path: "aderire",
    name: "Aderire",
    meta: {
        title: "Come fare per",
    },
    component: () => import("@/views/ComeFarePer.vue")
};
export const YUCCA = {
    path: "yucca",
    name: "Yucca",
    meta: {
        title: "Come fare per",
    },
    component: () => import("@/views/ComeFarePer.vue")
};
export const NEWS = {
    path: "news",
    name: "News",
    meta: {
        title: "News",
    },
    component: () => import("@/views/News.vue")
};
export const NEWS_DETAIL = {
    path: "news/:id",
    name: "NewsDetail",
    meta: {
        title: "News",
    },
    component: () => import("@/views/NewsDetail.vue")
};
export const CONTATTI = {
    path: "contatti",
    name: "Contatti",
    meta: {
        title: "Contatti",
    },
    component: () => import("@/views/Contatti.vue")
};
export const COOKIES = {
    path: "cookie-policy",
    name: "CookiePolicy",
    meta: {
        title: "Cookie policy",
    },
    component: () => import("@/views/Cookies.vue")
};

export const LAYOUT = {
    path: "/",
    name: "layout",
    component: () => import("@/layouts/MainLayout.vue"),
    redirect: HOME,
    children: [
        HOME,
        CATALOGO,
        CATALOGO_DETAIL,
        ENTI,
        ENTI_DETAIL,
        DATAVIZ,
        DATAVIZ_DETAIL,
        STORIE,
        STORIE_DETAIL,
        RICERCARE,
        RIUSARE,
        ADERIRE,
        YUCCA,
        NEWS,
        NEWS_DETAIL,
        CONTATTI,
        COOKIES
    ]
};

const routes = [
    LAYOUT,
    {
        path: '*', // porta alla home in caso di rotta non valida
        beforeEnter: (to, from, next) => {
            next('/home')
        }
    }
];

export default routes;