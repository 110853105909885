import { http, httpSmartdatanet } from "../boot/axios";

export const getEnti = (httpConfig = {}) => {
    const url = `/api/view/enti`;
    return http.get(url, httpConfig);
};

export const getEntiDetail = (id, httpConfig = {}) => {
    const url = `/api/view/enti/${id}`;
    return http.get(url, httpConfig);
};

export const getStorie = (httpConfig = {}) => {
    const url = `/api/view/storie`;
    return http.get(url, httpConfig);
};

export const getStorieDetail = (id, httpConfig = {}) => {
    const url = `/api/view/storie/${id}`;
    return http.get(url, httpConfig);
};

export const getNews = (httpConfig = {}) => {
    const url = `/api/view/news`;
    return http.get(url, httpConfig);
};

export const getNewsDetail = (id, httpConfig = {}) => {
    const url = `/api/view/news/${id}`;
    return http.get(url, httpConfig);
};

export const getTeam = (httpConfig = {}) => {
    const url = `/api/view/team`;
    return http.get(url, httpConfig);
};

export const getDataViz = (httpConfig = {}) => {
    const url = `/api/view/dataviz`;
    return http.get(url, httpConfig);
};

export const getDataVizDetail = (id, httpConfig = {}) => {
    const url = `/api/view/dataviz/${id}`;
    return http.get(url, httpConfig);
};

export const getComeRicercare = (httpConfig = {}) => {
    const url = `/api/view/comefareper/1`;
    return http.get(url, httpConfig);
};
export const getComeRiusare = (httpConfig = {}) => {
    const url = `/api/view/comefareper/2`;
    return http.get(url, httpConfig);
};
export const getComeAderire = (httpConfig = {}) => {
    const url = `/api/view/comefareper/3`;
    return http.get(url, httpConfig);
};
export const getUtilizzareYucca = (httpConfig = {}) => {
    const url = `/api/view/comefareper/4`;
    return http.get(url, httpConfig);
};

export const getDatasetCount = (httpConfig = {}) => {
    const url = `/api/datasets/statistics`;
    return http.get(url, httpConfig);
};

export const getHomeTagsList = (params, httpConfig = {}) => {
    const url = `/api/datasets/tags`;
    return http.get(url, { params }, httpConfig);
};

export const getHomeTopicsList = (httpConfig = {}) => {
    const url = `/api/temi/view`;
    return http.get(url, httpConfig);
};

export const getHomeOwnersList = (httpConfig = {}) => {
    const url = `/api/datasets/owners`;
    return http.get(url, httpConfig);
};

export const loadDatasetList = (searchParams, httpConfig = {}) => {
    const url = `/api/datasets/search`;
    return http.post(url, searchParams, httpConfig);
};

export const downloadDatasetListCsv = (searchParams, httpConfig = {}) => {
    const url = `/api/datasets/searchcsv`;
    return http.post(url, searchParams, httpConfig);
};

export const sendMail = (mailParams, httpConfig = {}) => {
    const url = `/api/mailcontatti`;
    return http.post(url, mailParams, httpConfig);
};

export const addComment = (commentParams, httpConfig = {}) => {
    const url = `/api/commentidataset`
    return http.post(url, commentParams, httpConfig);
};

export const getComments = (datasetId, httpConfig = {}) => {
    const url = `/bo/mostra?_format=json&dataset=${datasetId}`
    return http.get(url, httpConfig);
};

export const getIntestazione = (page, httpConfig = {}) => {
    const url = `/bo/api/intestazioni/view?_format=json&pagina=${page}`
    return http.get(url, httpConfig);
};

export const getTableData = (type, datasetId, httpConfig = {}) => {

    //const url = `/api/${datasetId}/DataEntities?$format=json&$top=15`
    const url = `/api/${datasetId}/${type}?$format=json&$top=15`
    return httpSmartdatanet.get(url, httpConfig);
};

export const getDatasetMetadata = (datasetId, httpConfig = {}) => {
    const url = `/metadataapi/api/v02/detail/${datasetId}`
    return httpSmartdatanet.get(url, httpConfig);
};