import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
    // mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
  })

  router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Open Data';
    next();
  });

  export default router