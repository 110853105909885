import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Notifications from "vue-notification";
import VueSocialSharing from 'vue-social-sharing'
import VueMatomo from 'vue-matomo'
import globals from './boot/globals'

Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(VueSocialSharing);

Vue.config.ignoredElements = [
  'yucca-sankey-diagram',
  'yucca-treemap-chart',
  'yucca-multi-chart',
  'yucca-choropleth-map'
];

Vue.use(VueMatomo, {
  host: 'https://ingestion.webanalytics.italia.it',
  siteId: 'kJ3ZkBrpLy',
  router: router
});

new Vue({
  render: h => h(App),
  store,
  router,
  vuetify,
  globals,
	mounted() {
		this.$nextTick(() => {
      document.body.addEventListener('keyup', function (e) {
        if (e.key === 'Tab') {
          var focused = Array.from(document.getElementsByClassName('keyFocus'));
          new Promise((resolve) => {
            focused.forEach(function(element) {
              element.classList.remove('keyFocus');
            });
            resolve();
          }).then(() => {        
            document.activeElement.classList.add('keyFocus');
          });
        }
      }, false);
		});
  },
}).$mount('#app')

